import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';

import './styles.scss';

let classNames = require('classnames');

const ActivitiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const block_list = [
    {
      title: 'La route',
      visuel: data.file.childImageSharp,
      subtitle: 'Le cœur de métierhistorique',
      text: 'La construction et l’entretien d’infrastructures routières constituent l’activité principale du Groupe. Très diversifiée, la Route bénéficie d’une large gamme de métiers et de savoir-faire : sécurité et signalisation routière, génie civil, bâtiment (construction neuve et réhabilitation, démolition-déconstruction)…',
      link: '/'
    },
    {
      title: 'Les matériaux',
      visuel: data.file.childImageSharp,
      subtitle: 'Production, distribution et recyclage de matériaux de construction',
      text: 'En amont et en aval de la construction et de l’entretien routiers, Colas opère une importante activité de matériaux de construction issus de la transformation du bitume ou des carrières et gravières du Groupe. Colas produit, distribue et recycle une grande diversité de matériaux, dans l’ensemble de ses implantations dans le monde.',
      link: '/'
    },
    {
      title: 'Le ferroviaire',
      visuel: data.file.childImageSharp,
      subtitle: 'Un pôle ferroviaire complet',
      text: 'L’activité Ferroviaire, exercée par Colas Rail et ses filiales, comprend entre autres la conception et l’ingénierie de grands projets complexes, la construction, le renouvellement et l’entretien des réseaux ferroviaires.',
      link: '/'
    },
    {
      title: 'Le transport d’eau et d’énergie',
      visuel: data.file.childImageSharp,
      subtitle: 'Construction et maintenance des réseaux',
      text: 'Cette activité, exercée par la filiale Spac, comprend la pose et l’entretien de pipelines et canalisations pour le transport d’eau et d’énergie. Du fait de nombreuses complémentarités avec les activités de Spac, l’activité dépollution de Colas Environnement est également rattachée au segment Transport d’eau et d’énergie.',
      link: '/'
    }
  ];
  let innerNavArr = [
    {
      section: 'intro_page_component',
      title: 'hide'
    }
  ];

  const setInnerNav = (section_class, title) => {
    let t = title?.replace(/<[^>]*>?/gm, '');
    innerNavArr.push({
      title: t,
      section: section_class
    });
  };

  return (
    <Layout>
      <Seo
        title="Colas Nos activités"
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'page_activities')}>
        <ScrollPageNav
          data={innerNavArr}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'Groupe', url: '/groupe' },
                currentPage: { title: 'Qui somme nous', url: '/about-us' }
              }}
            />
            <TitlePage
              color="color_white"
              title="Nos activités"
              description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
            />
          </div>
        </PageBanner>
        <IntroPage
          customClass="topZIndex"
          title={null}
          visuel={data.file.childImageSharp}
          size="border" //wrapper
          text="Colas intervient dans les domaines de la construction et de l’entretien des infrastructures de transport à travers trois activités principales : la Route (construction et entretien d’infrastructures routières, incluant la sécurité et la signalisation routières), les Matériaux de construction (production et recyclage) et le Ferroviaire (Colas Rail). Il exerce également une activité de transport d'eau et d'énergie (Spac)."
        />
        <div className="section_content_multiple">
          <ShapeBackground
            top="right"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page">
              {
                block_list.map((block, index) => {
                  setInnerNav('block_' + index + '_section', block.title);
                  return (
                    <section className={classNames('section_content', 'block_' + index + '_section')} key={index}>
                      <TextVisuelCTATwoCol
                        extremeLeft={(index % 2 == 0) ? true : false}
                        extremeRight={(index % 2 == 0) ? false : true}
                        orderInverse={(index % 2 == 0) ? false : true}
                        visuel={data.file.childImageSharp}
                        visuelSize='half'
                        title={{
                          titleSize: 'h2',
                          titleText: block.title,
                          subtitle: block.subtitle
                        }}
                        text={block.text}
                        cta={{
                          ctaText: 'DÉCOUVRIR L’ACTIVITÉ',
                          ctaLink: '/'
                        }}
                      />
                    </section>
                  );
                })
              }
            </div>
          </ShapeBackground>
        </div>
      </div>
    </Layout>
  );
};

export default ActivitiesPage;
